@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

@import "~bulmaswatch/flatly/bulmaswatch";
@import "~bulma/bulma.sass";

.__react_component_tooltip { pointer-events: auto !important; }

footer {
  padding: 3rem 1.5rem 6rem !important;
}

.footex {
    background: "#ABCDEF" !important;
}

.top-pad {
    margin-top: 3.25rem;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

.Content {
  height:220px;
   overflow:auto;
    background:#fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.audio-icon {
  cursor: pointer;
}

/* animation */
.waggle {
  animation: waggle 1s 3s forwards ease-out;
}

.start-now, .start-now:after {
  animation-delay: 0;
}

@keyframes waggle {
  0% {
    transform: none;
  }
  50% {
    transform: rotateZ(-20deg) scale(1.2);
  }
  60% {
    transform: rotateZ(25deg) scale(1.2);
  }
  67.5% {
    transform: rotateZ(-15deg) scale(1.2);
  }
  75% {
    transform: rotateZ(15deg) scale(1.2);
  }
  82.5% {
    transform: rotateZ(-12deg) scale(1.2);
  }
  85% {
    transform: rotateZ(0) scale(1.2);
  }
  100% {
    transform: rotateZ(0) scale(1);
  }
}

/*
body.has-navbar-fixed-top, html.has-navbar-fixed-top {
    padding-top: 4rem;
}
*/
.approot {
  padding-top: 1rem;
}

.navbar {
  border-radius: 0 !important;
}

.mt {
  margin-top: 120px;
}

.mp {
  margin-top: 15px;
}

.ml {
  margin-left: 10px;
}

.mr {
  margin-right: 0.75rem;
}

.mb {
  margin-left: 10px;
  margin-right: 10px;
}

.ml2 {
  margin-left: 25px;
}

.filler {
  height: 100px;
}

.fixed-height {
  height: 250px;
}

.filter-widget {
  height: 224px;
  overflow: auto;
  background: #fff;
}

/*
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/layout/section.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/form/shared.sass";
@import "~bulma/sass/form/tools.sass";
@import "~bulma/sass/form/input-textarea.sass";
*/
